import { Component, ElementRef, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { DeliveryOptionsService } from 'app/_services/delivery-options.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-options',
  templateUrl: './delivery-options.component.html',
  styleUrls: ['./delivery-options.component.scss']
})
export class DeliveryOptionsComponent implements OnInit {

  public isLoading: Boolean =true;
  public currentUser: any;
  public selectBasicLoading = true;
  public productData:any = [];

  public deliveryForm: FormGroup;
  public submitted = false;
  public loading = false;
  public radioButtonCheck = false;
  public customSelect = false;
  public customDayList:any = [];

  constructor(private elementRef: ElementRef,  private fb: FormBuilder, private _authenticationService: AuthenticationService, private deliverOption:DeliveryOptionsService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit(): void {
    this.customDayData();
    this.currentUser = this._authenticationService.currentUserValue;
    this.deliveryForm = this.fb.group({
      product_id: [null, [Validators.required]],
      selected_day: ['',Validators.required],
    });
    this.getProductList();
    console.log(this.customDayList.filter(item => item.checked).length);
  }

  get f() {
    return this.deliveryForm.controls;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }

  getProductList(){
    this.deliverOption.getProductData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false;
          this.selectBasicLoading=false;
          let arrMy = [];
          for (var i = 0; i < data.result.length; i++) {
            if (data.result[i].is_default == 1) {
              arrMy.push({id: data.result[i].id, name: data.result[i].product_name, selected_day:data.result[i].selected_day});
            }
          }
          this.productData = arrMy;
          /*single product selected*/
            if(this.productData.length > 0){
              if(this.productData[0].selected_day != 'daily' && this.productData[0].selected_day != 'alternate day'){
                /*set custom day start*/
                let checkMultipleDay = this.productData[0].selected_day.split(',');
                this.customDayList.filter(item => checkMultipleDay.indexOf(item.slug) >= 0).forEach(item => item.checked = true);
                this.radioButtonCheck = true;
                this.deliveryForm.setValue({
                  product_id: this.productData[0].id,
                  selected_day: 'custom',
                });
                this.customDay();
                /*set custom day end*/
              }else{
                this.deliveryForm.setValue({
                  product_id: this.productData[0].id,
                  selected_day: this.productData[0].selected_day,
                });
              }
            }
          /*single product selected*/
        }else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  customDay(){
     this.customSelect = true;
  }

  customDayRemove(){
    this.customSelect = false;
  }

  customDayData(){
    this.customDayList = [
      {
        slug: 'sunday',
        title: 'Sunday',
        checked: false,
      },
      {
        slug: 'monday',
        title: 'Monday',
        checked: false,
      },
      {
        slug: 'tuesday',
        title: 'Tuesday',
        checked: false,
      },
      {
        slug: 'wednesday',
        title: 'Wednesday',
        checked: false,
      },
      {
        slug: 'thursday',
        title: 'Thursday',
        checked: false,
      },
      {
        slug: 'friday',
        title: 'Friday',
        checked: false,
      },
      {
        slug: 'saturday',
        title: 'Saturday',
        checked: false,
      },
    ]
  }

  changeProduct(event:any){
    this.customDayRemove();
    for (var i = 0; i < this.productData.length; i++) {
      if (this.productData[i].id == event.id) {
        if(this.productData[i].selected_day != 'daily' && this.productData[i].selected_day != 'alternate day'){
          /*set custom day start*/
          let checkMultipleDay = this.productData[i].selected_day.split(',');
          this.customDayList.filter(item => checkMultipleDay.indexOf(item.slug) >= 0).forEach(item => item.checked = true);
          this.radioButtonCheck = true;
          this.deliveryForm.setValue({
            product_id: this.productData[i].id,
            selected_day: 'custom',
          });
          this.customDay();
          /*set custom day end*/
        }else{
          this.customDayList.forEach(item => item.checked = false);
          this.deliveryForm.setValue({
            product_id: this.productData[i].id,
            selected_day: this.productData[i].selected_day,
          });
        }
      }
    }
  }

  /** On Submit **/
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    let selectDay = [];
    if (this.deliveryForm.invalid) {
      this.deliveryForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
    }else {
      let formData = new FormData();
      formData.append('product_id', this.f.product_id.value);
      formData.append('user_id', this.currentUser.result.user_id);
      /*set custom day start*/
      if(this.f.selected_day.value == 'custom' || this.f.selected_day.value == undefined){
        let customDaySelect = this.customDayList.filter(item => item.checked);
        for (var c = 0; c <customDaySelect.length; c++) {
          selectDay.push(customDaySelect[c].slug);
        }
        formData.append('selected_day', selectDay.toString());
      }else{
        formData.append('selected_day', this.f.selected_day.value);
      }
      /*set custom day end*/
     
      if((this.customDayList.filter(item => item.checked).length <= 0) && (this.f.selected_day.value == 'custom' || this.f.selected_day.value == undefined)){
        this.toastr.error('Please Select CustomDay', 'Opps!');
      }else{
        this.deliverOption.setDelivery(formData).subscribe(data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.getProductList();
            this._router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this._router.navigate(['delivery-option']);
          }); 
            this.toastr.success(data.message, 'Success!');
          }
          else {
            this.toastr.error(data.message, 'Error!');
          }
        },err => {
          if (err.error.status_code == 400) {
            this.toastr.error(err.error.message, 'Opps!');
          }

          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
      }
    }
  }
}
