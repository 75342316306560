import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeliveryOptionsService {

  constructor(private http: HttpClient) { }

  getProductData(user_id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}product-list`, {user_id});
  }

  setDelivery(formData:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}set-devilery-options`, formData);
  }
}
