import { Component, ElementRef, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TomorrowOrderService } from 'app/_services/tomorrow-order.service';

@Component({
  selector: 'app-tomorrow-order',
  templateUrl: './tomorrow-order.component.html',
  styleUrls: ['./tomorrow-order.component.scss']
})
export class TomorrowOrderComponent implements OnInit {
  public currentUser: any;
  public selectBasicLoading = true;
  public addOrderForm: FormGroup;
  public loading = false;
  public submitted = false;
  public productId:any;
  public today:any
  public date:any
  public month:any;
  public year:any
  public selectDate:any;
  public activeDate:any;
  public firstDate:any;
  public checkBoxCheck: Boolean= false;
  public nextDisable:Boolean=false;
  public previousDisable:Boolean=false;
  public Days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  public monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
  public quantity = 0;

  constructor(private elementRef: ElementRef,  private fb: FormBuilder, private _authenticationService: AuthenticationService, private tomorrowOrderService:TomorrowOrderService, private toastr: ToastrService, private route: ActivatedRoute,private _router: Router) { }

  ngOnInit(): void {
    this.productId = localStorage.getItem('product_id');
   
    if(localStorage.getItem('tomorrow_order_qty') != undefined){
      this.quantity = Number(localStorage.getItem('tomorrow_order_qty'));
    }
    this.currentUser = this._authenticationService.currentUserValue;
    this.addOrderForm = this.fb.group({
      tomorrow_date: ['', [Validators.required]],
      tommorow_qty: ['', [Validators.required]],
      is_default: [''],
    });
    this.customDatepicker();
  }

  get f() {
    return this.addOrderForm.controls;
  }

  customDatepicker(){
    const currentDate = new Date();
    const timestamp = currentDate.getHours();
    if(13 <= timestamp){
        let nextDay = new Date();
        nextDay.setDate(new Date().getDate() + 2);
        this.activeDate = nextDay;
        this.f.tomorrow_date.setValue(this.activeDate);
        this.firstDate = nextDay.setHours(0,0,0,0);
        this.month = this.monthNames[(nextDay).getMonth()];
        this.date = nextDay.getDate();
        this.year = nextDay.getFullYear();
        this.today = this.Days[(nextDay).getDay()];
        this.previousDisable=true;
    }else{
        let tomorrowDay = new Date();
        tomorrowDay.setDate(new Date().getDate() + 1);
        this.activeDate = tomorrowDay;
        this.f.tomorrow_date.setValue(this.activeDate);
        this.firstDate = tomorrowDay.setHours(0,0,0,0);
        this.month = this.monthNames[(tomorrowDay).getMonth()];
        this.date = tomorrowDay.getDate();
        this.year = tomorrowDay.getFullYear();
        this.today = this.Days[(tomorrowDay).getDay()];
        this.previousDisable=true;
    }
  }

  nextDate(){
    this.previousDisable=false;
    let nextDay = new Date(this.activeDate);
    nextDay.setDate(nextDay.getDate() + 1);
    this.month = this.monthNames[(nextDay).getMonth()];
    this.date = nextDay.getDate();
    this.year = nextDay.getFullYear();
    this.today = this.Days[(nextDay).getDay()];
    this.activeDate = nextDay;
    this.f.tomorrow_date.setValue(this.activeDate);
  }

  previousDate(){
    let previous = new Date(this.activeDate);
    previous.setDate(previous.getDate() - 1);
    if(this.firstDate == previous.setHours(0,0,0,0)){
       this.previousDisable=true;
    }else{
      this.previousDisable=false;
    }
    this.month = this.monthNames[(previous).getMonth()];
    this.date = previous.getDate();
    this.year = previous.getFullYear();
    this.today = this.Days[(previous).getDay()];
    this.activeDate = previous;
    this.f.tomorrow_date.setValue(this.activeDate);
  }


  onSubmit(){
    this.submitted = true;
    if (!this.addOrderForm.invalid) {
      this.loading = true;
      if(this.f.tommorow_qty.value == 0){
        this.toastr.error('Litter not found', 'Opps!');
        return;
      }else{
        let formData = new FormData();
        formData.append('tomorrow_date', this.convert(this.f.tomorrow_date.value));
        formData.append('product_id', this.productId);
        formData.append('tommorow_qty', this.f.tommorow_qty.value);
        if(this.f.is_default.value == '' || this.f.is_default.value == false){
          formData.append('is_defualt', '0');
        }else{
          formData.append('is_defualt','1');
        }
        formData.append('user_id', this.currentUser.result.user_id);
        this.tomorrowOrderService.addTomorrowOrder(formData).subscribe(data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.addOrderForm.reset();
            this.toastr.success(data.message, 'Success!');
            localStorage.removeItem("product_id");
            this._router.navigate(['/home']);
          }
          else {
            this.toastr.error(data.message, 'Error!');
          }
        },err => {
          if (err.error.status_code == 400) {
            this.toastr.error(err.error.message, 'Opps!');
          }
        });
      }
    }
  }

  changeLiters(event:any){
    if(event.target.value % 1 != 0){
      if(event.target.value.toString().split(".")[0] == 0){
        this.quantity = 0.5
      }else{
        let first = (event.target.value.toString().split(".")[0]) + '.5'; 
        this.quantity = Number(first);
      } 
    }else{
      this.quantity = event.target.value + 0.5
    }
  }

  addLiters(){
    let newQuantity = this.quantity + 0.5
    this.quantity = newQuantity
  }

  removeLiters(){
    let newQuantity = this.quantity - 0.5
    if(newQuantity > 0){
      this.quantity = newQuantity
    }else{
      this.quantity = 0;
    }
    
  }

  // countChange(event:any){
  //   console.log(event)
  //   if(event % 1 != 0){
  //     if(event.toString().split(".")[0] == 0){
  //       this.quantity = 0.5
  //     }else{
  //       let first = (event.toString().split(".")[0]) + '.5'; 
  //       console.log(first);
  //       this.quantity = Number(first);
  //     } 
  //   }else{
  //     this.quantity = event + 0.5
  //   }
  // }

  convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), month, day].join("-");
  }

  onCheckboxChange(event:any){
    //console.log(event.target.value);
    if(event.target.value == false){
      this.checkBoxCheck = true;
    }else{
      this.checkBoxCheck = false;
    }
  }

}
 