<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="page-account-settings">
        <div class="row justify-content-center">
          <div class=" col-12 col-sm-12 col-md-8 col-lg-8">
            <div class="card">
              <div class="card-body">
                  <form class="validate-form" [formGroup]="addOrderForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <!-- <div class="swiper-responsive-breakpoints text-center swiper-container px-4 py-2" [swiper]="swiperResponsive">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let cc of customCalendar">
                                <h5 class="text-primary"> {{cc.month | date: 'MMM'}}, {{cc.year}}</h5>
                                <h3 class="text-primary">{{cc.date}}, {{cc.today}}</h3>
                            </div>
                        </div>
                       
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div> -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-6">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group mt-2 mb-3">
                                        <div class="d-flex align-items-center  justify-content-between">
                                            <div>
                                                <button type="button" [disabled]="previousDisable"  (click)="previousDate()"class="btn btn-icon rounded-circle btn-outline-primary" rippleEffect>
                                                    <span [data-feather]="'chevron-left'" [size]="20" [inner]="true"></span>
                                                </button>
                                            </div>
                                            <div>
                                                <h5 class="text-primary"> {{month}}, {{year}}</h5>
                                                <h3 class="text-primary">{{date}}, {{today}}</h3>
                                            </div>
                                            <div>
                                                <button type="button" [disabled]="nextDisable"  (click)="nextDate()"  class="btn btn-icon rounded-circle btn-outline-primary" rippleEffect>
                                                    <span [data-feather]="'chevron-right'" [size]="20" [inner]="true"></span>
                                                </button>
                                            </div>
                                        </div> 
                                        <div *ngIf="submitted && f.tomorrow_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.tomorrow_date.errors }">
                                            <div *ngIf="f.tomorrow_date.errors.required">Date is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group mb-3"> 
                                        <label  for="tomorrow_date">Litres</label>
                                        <span class="required" aria-required="true"> * </span><br>
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <button type="button" (click)="removeLiters()" class="rounded btn btn-sm btn-icon btn-primary" rippleEffect>
                                                    <span [data-feather]="'minus'" size="22"></span>
                                                </button>
                                            </div>
                                            <input type="text" style="font-size: 2rem; border: none; background: transparent;" readonly  (change)="changeLiters($event)" [ngModel]="quantity" formControlName="tommorow_qty" class="form-control text-center text-dark" value="{{quantity}}" placeholder="0.0" aria-label="0.0" aria-describedby="0.0"/>
                                            <div class="input-group-append">
                                                <button type="button" (click)="addLiters()" class="rounded btn btn-sm btn-icon btn-primary" rippleEffect>
                                                    <span [data-feather]="'plus'" size="22"></span>
                                                </button>
                                            </div>
                                        </div>
                                        <h4 class="text-dark text-center"><small>Liters</small></h4>
                                        <!-- <div class="d-flex   align-items-baseline justify-content-between">
                                            <div>
                                                <button type="button" (click)="removeLiters()" class="btn btn-sm btn-icon btn-primary" rippleEffect>
                                                    <span [data-feather]="'minus'" size="22"></span>
                                                </button>
                                            </div>
                                            <div>
                                                <h4 class="text-dark" *ngIf="quantity == 0">0.0<br><small>Liters</small></h4>
                                                <h4 class="text-dark" *ngIf="quantity != 0">{{quantity}}<br><small>Liters</small></h4>
                                            </div>
                                            <div>
                                                <button type="button" (click)="addLiters()" class="btn btn-sm btn-icon btn-primary" rippleEffect>
                                                    <span [data-feather]="'plus'" size="22"></span>
                                                </button>
                                            </div>
                                        </div>-->
                                        <!-- <core-touchspin *ngIf="quantity == 0" [minValue]="0" [size]="'lg'" (onChange)="countChange($event)" [numberValue]="" [stepValue]=".5"></core-touchspin>
                                        <core-touchspin *ngIf="quantity != 0" [minValue]="0" [size]="'lg'" (onChange)="countChange($event)" [numberValue]="quantity" [stepValue]=".5"></core-touchspin>  -->
                                        <div *ngIf="submitted && f.tomorrow_date.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.tomorrow_date.errors }">
                                            <div *ngIf="f.tomorrow_date.errors.required">Date is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 ">
                                    <div class="form-group mb-3  d-flex justify-content-center"> 
                                        <div class="custom-control custom-control-primary custom-checkbox">
                                            <input type="checkbox" class="custom-control-input" id="is_default"  formControlName="is_default" (change)="onCheckboxChange($event)" />
                                            <label class="custom-control-label" for="is_default">Default</label>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button [disabled]="loading" class="btn btn-primary mb-2" type="submit" rippleEffect>
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit
                                        </button>
                                        <!-- <p class="text-primary">All alternations for tomorrow order {{date | date:'dd'}}-{{month}}-{{year}}  accepted only before 01:00pm.</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>
    