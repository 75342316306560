import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  addFeedback(user_id: any, feedback_type:any, feedback_text:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}feedback-save`, {user_id, feedback_type, feedback_text});
  }
}
