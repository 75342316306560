import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReferAndEarnService {

  constructor(private http: HttpClient) { }

  getReferralsData(user_id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}my-referrals`, {user_id});
  }
}
 