import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http: HttpClient) { }

  sendOtp(mobile_number: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}forgot-password-request`, {mobile_number});
  }

  setPassword(user_id:any, otp:any, password:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}forgot-password`, {user_id, otp, password});
  }
}
 