import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ForgotPasswordService } from 'app/_services/forgot-password.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public coreConfig: any;
  public forgotPasswordForm: FormGroup;
  public submitted = false;

  public passwordTextType: boolean;
  public isForgotForm:boolean = true;
  public isOtpForm:boolean = false;
  public loading:boolean = false;
  public loadingOtp:boolean = false;

  public otpForm: FormGroup;
  public otpSubmitted = false;
  public userId:any;
  public otp:any;
  public viewMobile:any;

 // Private
 private _unsubscribeAll: Subject<any>;
 /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService,  private fb: FormBuilder, private forgotPasswordService: ForgotPasswordService, private toastr: ToastrService,private _router: Router) { 
    this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      mobile: ['', [Validators.required,Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]]
    });

    this.otpForm = this.fb.group({
      //mobile: ['', [Validators.required,Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      otp: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      password: ['', Validators.required],
    });

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  /**
   * Toggle password
   */
   togglePasswordTextType(me: any) {
    this.passwordTextType = !this.passwordTextType;
  }

  onSubmit() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }else{
      this.loading = true;
      this.forgotPasswordService.sendOtp(this.f.mobile.value).subscribe(
      data => {
        if (data.status) {
          this.loading = false;
          this.submitted = false;
          this.userId = data.result.user_id;
          this.viewMobile = this.f.mobile.value;
          this.isForgotForm = false;
          this.isOtpForm = true;
          this.forgotPasswordForm.reset();
          this.toastr.success(data.message, 'Success!');
        }
        else {
          this.toastr.error(data.message, 'Opps!');
        }
      },err => {
        this.toastr.error(err.error.message, 'Opps!');
      });
    }
  }

  onOtpChange(otp) {
    this.otp = otp;
    if(this.otp.length == 4){
      this.o.otp.setValue(this.otp);
    }else{
      this.o.otp.setValue(undefined);
    }
  }
  

  onSubmitOtp() {
    this.isForgotForm = false;
    this.otpSubmitted = true;
    if (this.otpForm.invalid) {
      if(this.otp == undefined){
        this.toastr.error("Fill OTP", 'Opps!');
      }
      return;
    }else{
      this.loadingOtp = true;
      this.forgotPasswordService.setPassword(this.userId,this.o.otp.value,this.o.password.value).subscribe(
        data => {
          if (data.status) {
            this.submitted = false;
            this.isForgotForm = false;
            this.isOtpForm = false;
            this.loadingOtp = true;
            this.userId = '';
            this.viewMobile = '';
            this.otpForm.reset();
            this._router.navigate(['/login']);
            this.toastr.success(data.message, 'Success!');
          }
          else {
            this.toastr.error(data.message, 'Success!');
          }
        },err => {
          this.toastr.error(err.error.message, 'Opps!');
      });
    }
  }

  /**
   * On destroy
   */
   ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
