<div class="auth-wrapper auth-v1 px-2" *ngIf="isForgotForm">
    <div class="auth-inner py-2">
      <div class="card mb-0">
        <div class="card-body">
          <a href="javascript:void(0);" class="brand-logo">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="90" />
          </a>
  
          <h4 class="card-title mb-1">Forgot Password? 🔒</h4>
          <p class="card-text mb-2">Enter your mobile and we'll send you instructions to reset your password</p>
  
          <form class="auth-forgot-password-form mt-2" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="forgot-password-mobile" class="form-label">Mobile <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="1111111111" formControlName="mobile" aria-describedby="forgot-password-mobile" tabindex="1" autofocus [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"/>
              <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                <div *ngIf="f.mobile.errors.minLength">Minimum Length 10 digit</div>
                <div *ngIf="f.mobile.errors.maxLength">Maximum Length 10 digit</div>
                <div *ngIf="f.mobile.errors.pattern">Mobile Not Properly</div>
              </div>
            </div>
            <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Send OTP
            </button>
          </form>
          <p class="text-center mt-2">
            <a routerLink="/login">
              <i data-feather="chevron-left" class="mb-25"></i> Back to login
            </a>
          </p>
        </div>
      </div>
    </div>
</div>

<div class="auth-wrapper auth-v1 px-2" *ngIf="isOtpForm">
    <div class="auth-inner py-2">
      <div class="card mb-0">
        <div class="card-body">
          <a href="javascript:void(0);" class="brand-logo">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="90" />
          </a>
          <h4 class="card-title mb-1 text-center">Forgot Password? 🔒</h4>
          <form class="auth-forgot-password-form mt-2" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
            <div class="form-group">
              <label for="forgot-password-mobile" class="form-label">Mobile</label>
              <input type="text" class="form-control" value="{{viewMobile}}" readonly placeholder="1111111111" aria-describedby="forgot-password-mobile" tabindex="1" autofocus />
            </div>
            <div class="form-group">
                <label for="forgot-password-mobile" class="form-label">OTP <span class="text-danger">*</span></label>
                <ng-otp-input [ngClass]="'text-center'"  [formCtrl]="form-control" [containerClass]="true" [allowNumbersOnly]="true" tabindex="2" (onInputChange)="onOtpChange($event)"  [config]="{length:4}" [inputClass]="{ 'is-invalid': otpSubmitted && o.otp.errors }"></ng-otp-input>
                <div *ngIf="otpSubmitted && o.otp.errors" class="invalid-feedback">
                    <div *ngIf="o.otp.errors.required">Otp is required</div>
                    <div *ngIf="o.otp.errors.minLength">Minimum Length 4 digit</div>
                    <div *ngIf="o.otp.errors.maxLength">Maximum Length 4 digit</div>
                </div>
            </div>
            <div class="form-group">
                <label for="login-password">Password </label>
                <span class="required text-danger">*</span>
                <div class="input-group input-group-merge form-password-toggle">
                <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password" class="form-control form-control-merge"  tabindex="3" [ngClass]="{ 'is-invalid error': otpSubmitted && o.password.errors }" placeholder="············" aria-describedby="login-password" />
                    <div class="input-group-append">
                        <span class="input-group-text cursor-pointer">
                            <i class="feather font-small-4" [ngClass]="{'icon-eye-off': passwordTextType,'icon-eye': !passwordTextType}"(click)="togglePasswordTextType(0)"></i>
                        </span>
                    </div>
                </div>
                <div *ngIf="otpSubmitted && o.password.errors" class="invalid-feedback" [ngClass]="{ 'd-block': otpSubmitted && o.password.errors }" >
                    <div *ngIf="o.password.errors.required">Password is required</div>
                </div>
            </div>
            <button [disabled]="loadingOtp" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>Submit
            </button>
          </form>
        </div>
      </div>
    </div>
</div>
   