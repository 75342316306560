<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
    <!-- Navbar brand -->
    <ul class="nav navbar-nav flex-row">
        <li class="nav-item">
            <a class="navbar-brand" [routerLink]="['/']">
                <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="180" />
        </span>
            </a>
        </li>
    </ul>
    <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
    <div class="bookmark-wrapper d-flex align-items-center">
        <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
        <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
            <li class="nav-item">
                <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
                    <span [data-feather]="'menu'" [class]="'ficon'"></span>
                </a>
            </li>
        </ul>
        <!--/ Menu Toggler -->

        <!-- Toggle skin -->
        <li class="nav-item d-none d-lg-block">
            <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
            </a>
        </li>
       
    </div>

    <ul class="nav navbar-nav align-items-center ml-auto">
        <li class="nav-item ">
            <a type="button" routerLink="/notification" class="nav-link nav-link-style btn" >
                <i data-feather="bell" class="ficon font-medium-5 feather"></i>
            </a>
        </li>
        <!-- User Dropdown -->
        <li ngbDropdown class="nav-item dropdown-user">
          <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
            <div class="user-nav d-sm-flex d-none">
                <span class="user-name font-weight-bolder" *ngIf="currentUser && currentUser !== null && currentUser.result !== null"> {{ currentUser.result.name }} </span>
            </div>
            <span class="avatar bg-primary " *ngIf="currentUser && currentUser.result.profile_photo == null">
                <div class="avatar-content">{{ currentUser.result.name | initials }}</div>
            </span>
            <span class="avatar " *ngIf="currentUser && currentUser.result.profile_photo != null" style="background-color: #fff;">
                <span class="avatar-content">
                    <img [src]="currentUser.result.profile_photo" alt="{{ currentUser.result.name | initials }}" width="32" height="32" />
                </span>
            </span>
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
              <a ngbDropdownItem [routerLink]="['/profile']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a>
              <div class="dropdown-divider"></div>
            <a ngbDropdownItem (click)="logout()" [routerLink]="['/']"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
        </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>