<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row ">
                <div class="col-md-12 col-12">
                    <form class="validate-form" [formGroup]="calendarForm" autocomplete="off">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="form-group">
                                            <label for="product_id">Select Product</label>
                                            <span class="required" aria-required="true"> * </span>
                                            <ng-select [items]="productData" (change)="changeProduct($event);" id="product_id" [clearable]="false" [loading]="selectBasicLoading" bindValue="id" bindLabel="name" formControlName="product_id" placeholder="Select Product"> </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- calendar -->
                            <div class="col-12 col-md-3 col-sm-12 justify-content-center"></div>
                            <div class="col-12 col-md-6 col-sm-12 justify-content-center">
                                <div class="form-group position-relative">
                                    <div class="card shadow-none border-0 mb-0 rounded-0">
                                        <div class="card-body pb-0">
                                            <div class="overlay" *ngIf="isLoading">
                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <div>
                                                    Please Wait...
                                                </div>
                                            </div>
                                            <full-calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- calendar -->
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </div>
</div>