import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

// const httpOptions = {
//   headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
// };

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  addRegister(registerData: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}user-register`, registerData);
  }

  verifyOtp(user_id:any, apiToken:string, otp:any): Observable<any> {
    let NewHeaders = new HttpHeaders();
    NewHeaders = NewHeaders.set('APITOKEN', apiToken);
    return this.http.post(`${environment.apiUrl}verify-otp`, {user_id, otp},{ headers : NewHeaders});
  }

  reSendOtp(user_id:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}resend-otp`, {user_id});
  }

}
