import { Component, ElementRef, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RechargeHistoryService } from 'app/_services/recharge-history.service';

@Component({
  selector: 'app-recharge-history',
  templateUrl: './recharge-history.component.html',
  styleUrls: ['./recharge-history.component.scss']
})
export class RechargeHistoryComponent implements OnInit {

  public currentUser: any;
  public rechargeHistory: any;
  public isLoading: Boolean =true;

  constructor(private _authenticationService: AuthenticationService, private rechargeHistoryService:RechargeHistoryService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.getRechargeHistoryList();
  } 

  getRechargeHistoryList(){
    this.rechargeHistoryService.getRechargeHistory(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading=false;
          this.rechargeHistory = data.result;
        }
        else {
          this.isLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },err => {
        this.isLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }
} 
