<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);">
            <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="50" />
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-6 align-items-center">
            <div class="w-100 d-lg-flex align-items-center justify-content-center">
                <img class="img-fluid" [src]="coreConfig.layout.skin === 'dark' ? 'assets/images/pages/login-v2-dark.png' : 'assets/images/pages/login-v2.png'" alt="Login V2" />
            </div>
        </div>
        <!-- /Left Text-->
        <div class="col-lg-6 align-items-center auth-bg px-2 p-lg-5">
            <div  *ngIf="isRegisterForm">
                <form class="auth-login-form mt-2" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-name">Name</label>
                                <span class="required text-danger" aria-required="true"> * </span>
                                <input type="text" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Enter Name Number" aria-describedby="register-name" autofocus="" tabindex="1" />
                                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                    <div *ngIf="f.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-mobile">Mobile</label>
                                <span class="required" aria-required="true"> * </span>
                                <input type="text" formControlName="mobile_number" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile_number.errors }" placeholder="Enter Mobile Number" aria-describedby="register-mobile" autofocus="" tabindex="2" />
                                <div *ngIf="submitted && f.mobile_number.errors" class="invalid-feedback">
                                    <div *ngIf="f.mobile_number.errors.required">Mobile is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-email">Email</label>
                                <span class="required text-danger" aria-required="true"> * </span>
                                <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Enter Email" aria-describedby="register-email" autofocus="" tabindex="3" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.email">Enter valid email address</div>
                                    <div *ngIf="f.email.errors.required">Enter email address</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-address1">Address Line 1</label>
                                <span class="required text-danger" aria-required="true"> * </span>
                                <input type="text" formControlName="address1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" placeholder="Enter Address Line 1" aria-describedby="register-address1" autofocus="" tabindex="4" />
                                <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                    <div *ngIf="f.address1.errors.required">Address Line 1</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-address2">Address Line 2</label>
                                <input type="text" formControlName="address2" class="form-control" placeholder="Enter Address Line 2" aria-describedby="register-address2" autofocus="" tabindex="5" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label class="form-label" for="register-address3">Address Line 3</label>
                                <input type="text" formControlName="address3" class="form-control" placeholder="Enter Address Line 3" aria-describedby="register-address3" autofocus="" tabindex="6" />
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="form-group">
                                <label class="form-label" for="register-landmark">Landmark</label>
                                <span class="required text-danger" aria-required="true"> * </span>
                                <input type="text" formControlName="landmark" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.landmark.errors }" placeholder="Enter Landmark" aria-describedby="register-landmark" autofocus="" tabindex="7" />
                                <div *ngIf="submitted && f.landmark.errors" class="invalid-feedback">
                                    <div *ngIf="f.landmark.errors.required">Enter landmark</div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="form-group">
                              <label  for="state_id">Select State</label>
                              <span class="required text-danger" aria-required="true"> * </span>
                              <ng-select [items]="stateData" [clearable]="false"  id="state_id" (change)="getCityList($event)" [loading]="selectBasicLoading" bindValue="id" bindLabel="state_name"  placeholder="Select State" formControlName="state_id" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" tabindex="8"> </ng-select>
                              <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">Select State</div>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="form-group">
                              <label  for="city_id">Select City</label>
                              <span class="required text-danger" aria-required="true"> * </span>
                              <ng-select [items]="cityData" [clearable]="false"  id="city_id" (change)="getPincodeList($event)" [loading]="selectCityBasicLoading" bindValue="id" bindLabel="city_name"  placeholder="Select City" formControlName="city_id" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }" tabindex="10"> </ng-select>
                              <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">Select City</div>
                              </div>  
                            </div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                            <div class="form-group">
                              <label  for="pincode_id">Select Pincode</label>
                              <span class="required text-danger" aria-required="true"> * </span>
                              <ng-select [items]="pincodeData" [clearable]="false"  id="pincode_id" [loading]="selectPincodeBasicLoading" bindValue="id" bindLabel="pincode"  placeholder="Select Pincode" formControlName="pincode_id" [ngClass]="{ 'is-invalid': submitted && f.pincode_id.errors }" tabindex="8"> </ng-select>
                              <div *ngIf="submitted && f.pincode_id.errors" class="invalid-feedback">
                                <div *ngIf="f.pincode_id.errors.required">Select Pincode</div>
                              </div>   
                            </div>
                        </div>
                        <!-- <div class="col-sm-6 col-md-4 col-lg-4 col-12">
                            <div class="form-group">
                                <label  for="birthdate">Birth Date</label>
                                <ng2-flatpickr [config]="DefaultDateStart" id="birthdate" formControlName="birthdate" placeholder="Select Birth Date.." tabindex="11"></ng2-flatpickr>
                            </div>
                        </div> -->
                        <div class="col-sm-6 col-md-4 col-lg-4 col-12">
                            <div class="form-group">
                                <label for="login-password">Password </label>
                                <span class="required text-danger">*</span>
                                <div class="input-group input-group-merge form-password-toggle">
                                <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password" class="form-control form-control-merge" [ngClass]="{ 'is-invalid error': submitted && f.password.errors }" placeholder="············" aria-describedby="login-password" tabindex="12"/>
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer">
                                            <i class="feather font-small-4" [ngClass]="{'icon-eye-off': passwordTextType,'icon-eye': !passwordTextType}"(click)="togglePasswordTextType(0)"></i>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.password.errors }" >
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 col-12">
                            <div class="form-group">
                                <label for="register-password" class="form-label">Confirm Password </label>
                                <span class="required text-danger">*</span>
                                <div class="input-group input-group-merge form-password-toggle">
                                    <input [type]="passwordTextType1 ? 'text' : 'password'" formControlName="confirmPassword" class="form-control form-control-merge" placeholder="············" aria-describedby="register-confirmPassword" tabindex="13" [ngClass]="{ 'is-invalid error': submitted && f.confirmPassword.errors }"
                                    />
                                    <div class="input-group-append">
                                        <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{'icon-eye-off': passwordTextType1,'icon-eye': !passwordTextType1 }" (click)="togglePasswordTextType(1)" ></i></span>
                                    </div>
                                </div>
                                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.confirmPassword.errors }">
                                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-4 col-12">
                            <div class="form-group">
                                <label class="form-label" for="register-referral">Referral</label>
                                <input type="text" formControlName="referral" class="form-control" placeholder="Enter Referral" aria-describedby="register-referral" autofocus="" tabindex="14" />
                            </div>
                        </div>
                    </div>
                    
                    <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
                    </button>
                </form>
                <p class="text-center mt-2">
                    <span>Already have an account?</span>
                    <a routerLink="/login"><span>&nbsp;Login</span></a>
                </p>
            </div>
            <div *ngIf="isOtpForm">
                <div class="card mb-0">
                  <div class="card-body">
                    <form class="auth-forgot-password-form mt-2" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
                      <div class="form-group">
                          <label for="forgot-password-mobile" class="form-label">OTP <span class="text-danger">*</span></label>
                          <ng-otp-input [ngClass]="'text-center'"  [formCtrl]="form-control" [containerClass]="true" [allowNumbersOnly]="true" tabindex="2" (onInputChange)="onOtpChange($event)"  [config]="{length:4}" [inputClass]="{ 'is-invalid': otpSubmitted && o.otp.errors }"></ng-otp-input>
                          <div *ngIf="otpSubmitted && o.otp.errors" class="invalid-feedback">
                              <div *ngIf="o.otp.errors.required">Otp is required</div>
                              <div *ngIf="o.otp.errors.minLength">Minimum Length 4 digit</div>
                              <div *ngIf="o.otp.errors.maxLength">Maximum Length 4 digit</div>
                          </div>
                      </div>
                      <button [disabled]="loadingOtp" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                          <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>Submit
                      </button>
                    </form>
                    <p class="text-center mt-2">
                        <a role="button" style="color:#024e26" (click)="onReSendOtp()"><b>&nbsp;Resend Otp</b></a>
                    </p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>