<div class="auth-wrapper auth-v2">
  <div class="auth-inner row m-0">
      <!-- Brand logo-->
      <a class="brand-logo" href="javascript:void(0);">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="50" />
      </a>
      <!-- /Brand logo-->
      <!-- Left Text-->
      <div class="d-none d-lg-flex col-lg-7 align-items-center">
          <div class="w-100 d-lg-flex align-items-center justify-content-center">
              <img class="img-fluid" [src]="
          coreConfig.layout.skin === 'dark'
            ? 'assets/images/pages/login-v2-dark.png'
            : 'assets/images/pages/login-v2.png'
        " alt="Login V2" />
          </div>
      </div>
      <!-- /Left Text-->
      <!-- Login-->
      <div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5" *ngIf="isLoginForm">
          <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
              <h2 class="card-title font-weight-bold mb-1">Welcome to Go Organic! 👋</h2>
              <p class="card-text mb-2">Please sign-in to your account and start the adventure</p>

              <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                  <div class="alert-body">
                      <p>{{ error }}</p>
                  </div>
              </ngb-alert>

              <form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                      <label class="form-label" for="login-email">Mobile</label>
                      <input type="text" formControlName="mobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" placeholder="Enter Mobile Number" aria-describedby="login-mobile" autofocus="" tabindex="1" />
                      <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                          <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                      </div>
                  </div>
                  <div class="form-group">
                      <div class="d-flex justify-content-between">
                          <label for="login-password">Password</label
            ><a routerLink="/forgot-password"><small>Forgot Password?</small></a>
          </div>
          <div class="input-group input-group-merge form-password-toggle">
            <input
              [type]="passwordTextType ? 'text' : 'password'"
              formControlName="password"
              class="form-control form-control-merge"
              [ngClass]="{ 'is-invalid error': submitted && f.password.errors }"
              placeholder="············"
              aria-describedby="login-password"
              tabindex="2"
            />
            <div class="input-group-append">
              <span class="input-group-text cursor-pointer"
                ><i
                  class="feather font-small-4"
                  [ngClass]="{
                    'icon-eye-off': passwordTextType,
                    'icon-eye': !passwordTextType
                  }"
                  (click)="togglePasswordTextType()"
                ></i
              ></span>
            </div>
          </div>
          <div
            *ngIf="submitted && f.password.errors"
            class="invalid-feedback"
            [ngClass]="{ 'd-block': submitted && f.password.errors }"
          >
            <div *ngIf="f.password.errors.required">Password is required</div>
          </div>
        </div>
              <button [disabled]="loading" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Sign in
              </button>
          </form>
          <p class="text-center mt-2">
              <span>New on our platform?</span>
              <a routerLink="/register"><span>&nbsp;Create an account</span></a>
          </p>
          </div>
      </div>

      <div class="d-flex col-lg-5 align-items-center auth-bg px-2 p-lg-5" *ngIf="isOtpForm">
        <div class="card mb-0">
          <div class="card-body">
            <form class="auth-forgot-password-form mt-2" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
              <div class="form-group">
                  <label for="forgot-password-mobile" class="form-label">OTP <span class="text-danger">*</span></label>
                  <ng-otp-input [ngClass]="'text-center'"  [formCtrl]="form-control" [containerClass]="true" [allowNumbersOnly]="true" tabindex="2" (onInputChange)="onOtpChange($event)"  [config]="{length:4}" [inputClass]="{ 'is-invalid': otpSubmitted && o.otp.errors }"></ng-otp-input>
                  <div *ngIf="otpSubmitted && o.otp.errors" class="invalid-feedback">
                      <div *ngIf="o.otp.errors.required">Otp is required</div>
                      <div *ngIf="o.otp.errors.minLength">Minimum Length 4 digit</div>
                      <div *ngIf="o.otp.errors.maxLength">Maximum Length 4 digit</div>
                  </div>
              </div>
              <button [disabled]="loadingOtp" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                  <span *ngIf="loadingOtp" class="spinner-border spinner-border-sm mr-1"></span>Submit
              </button>
            </form>
            <p class="text-center mt-2">
                <a role="button" style="color:#024e26" (click)="onReSendOtp()"><b>&nbsp;Resend Otp</b></a>
            </p>
          </div>
        </div>
      </div>
      <!-- /Login-->
  </div>
</div>