import { Component, ElementRef, OnInit, ChangeDetectorRef,ViewChild} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DeliveryOptionsService } from 'app/_services/delivery-options.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ProductsService } from 'app/_services/products.service';
import { ExternalLibraryService } from './util';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  
  @ViewChild("modalSM",{static:true}) modalSM:ElementRef;

  public isLoading: Boolean =true;
  public loading:Boolean = false;
  public isModelLoading: Boolean =true;
  public currentUser: any;
  public productData: any;
  public productDetails: any;
  public min_qty_to_recharge:any;
  public weight_for_shipping_charge:any;
  public orderDate:any;
  public orderQuantity:any;
  public localProductId:any = 0;

  constructor(private elementRef: ElementRef,  private fb: FormBuilder, private _authenticationService: AuthenticationService, private deliverOption:DeliveryOptionsService,  private productsService:ProductsService, private modalService: NgbModal, private toastr: ToastrService, private _router: Router, 
    private razorpayService: ExternalLibraryService, private cd:  ChangeDetectorRef) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.getProductList();

    const currentDate = new Date();
    const timestamp = currentDate.getHours();
    if(13 <= timestamp){
      let nextDay = new Date();
      this.orderDate = nextDay.setDate(new Date().getDate() + 2);
    }else{
      let nextDay = new Date();
      this.orderDate = nextDay.setDate(new Date().getDate() + 1);
    }
    this.razorpayService.lazyLoadLibrary('https://checkout.razorpay.com/v1/checkout.js').subscribe();
    this.localProductId = localStorage.getItem('recharge_p_id');
  }

  getProductList(){
    this.deliverOption.getProductData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false;
          this.productData = data.result;
          if(this.localProductId != null){
            for (var i = 0; i < this.productData.length; i++) {
              if (this.productData[i].id == Number(this.localProductId)) {
                this.productDetails = this.productData[i];
              }
            }
            this.loading=false;
            this.isModelLoading = false;
            this.modalService.open(this.modalSM, {
              centered: true,
              size: 'lg'
            });
            this.min_qty_to_recharge = this.productDetails.min_qty_to_recharge;
            this.weight_for_shipping_charge = this.productDetails.weight_for_shipping_charge;
            localStorage.removeItem('recharge_p_id');
          }
        }else {
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  viewDetails(details: any,modalSM) {
    //console.log(details);
    this.loading=false;
    this.isModelLoading = false;
    this.modalService.open(modalSM, {
      centered: true,
      size: 'lg'
    });
    this.productDetails = details;
    this.min_qty_to_recharge = this.productDetails.min_qty_to_recharge;
    this.weight_for_shipping_charge = this.productDetails.weight_for_shipping_charge;
    localStorage.removeItem('recharge_p_id');
  } 

  countChange(event){
    this.orderQuantity = event;
    this.loading=false;
  }

  addOrder(singleProduct:any) {
    let total_amount;
    this.loading=true;
   
    if(this.orderQuantity == undefined){
      this.orderQuantity = this.min_qty_to_recharge;
      total_amount = ((singleProduct.product_price - singleProduct.product_discount) * this.min_qty_to_recharge)
    }else{
      total_amount = ((singleProduct.product_price - singleProduct.product_discount) * this.orderQuantity);
    }
    if(this.orderQuantity < this.min_qty_to_recharge){
      this.toastr.error('You can not recharge less than '+ this.min_qty_to_recharge, 'Opps!');
    }else{
      let product_amount = (singleProduct.product_price - singleProduct.product_discount);
      this.productsService.getOrderId(total_amount,this.currentUser.result.user_id).subscribe(
        data => {
          if (data.status) {
            this.payWithRazor(data.result.order_id, data.result.amount, total_amount,singleProduct, product_amount);
          }else {
            this.toastr.error(data.message, 'Error!');
          }
        },
        err => {
          this.toastr.error(err.error.message, 'Opps!');
        }
      );
    }
  }

  payWithRazor(orderId:any, amount:any, total_amount:any, singleProduct:any, product_amount:any) {
    const options: any = {
      "key": 'rzp_live_ulTyAbo0SQVOvP',
      "amount": amount, 
      "currency": 'INR',
      "name": 'Go Organic',
      "description": 'A2 Cow Healthy Milk', 
      "image": 'assets/images/logo/go-organic-logo.png', 
      "order_id": orderId, 
      "modal": {
        escape: false,
      },
      "prefill": {
        "name": this.currentUser.result.name,
        "email": this.currentUser.result.email,
        "contact":this.currentUser.result.mobile_number,
      },
      "notes": {
        
      },
      "theme": {
        "color": "#024e26"
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      if(error == undefined){
        let formData = new FormData();
        formData.append('user_id',this.currentUser.result.user_id);
        formData.append('product_id',singleProduct.id);
        formData.append('quentity',this.orderQuantity);
        formData.append('unit_price',product_amount);
        formData.append('total_amount',total_amount);
        formData.append('razorpayPaymentId',response.razorpay_payment_id);
        formData.append('orderId',response.razorpay_order_id);
        formData.append('signature',response.razorpay_signature);
        this.productsService.addRecharge(formData).subscribe(
          data => {
            if (data.status) {
             this.loading=false;
             this.toastr.success(data.message, 'Success!');
             this.modalService.dismissAll();
             this.orderQuantity='';
            }else {
              this.toastr.error(data.message, 'Error!');
            }
          },
          err => {
            this.toastr.error(err.error.message, 'Opps!');
          }
        );
      }else{
        this.loading=false;
        this.toastr.error('Payment Failed Try Again', 'Opps!');
      }
    });

    options.modal.ondismiss = (() => {
      this.loading=false;
      this.toastr.error('Order cancelled', 'Error!');
    });

    const rzp = new this.productsService.nativeWindow.Razorpay(options);
    rzp.open();
  }

}
 