<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="page-account-settings">
        <div class="row ">
          <div class="col-md-12 col-12">
            <div class="card">
              <div class="card-body">
                  <form class="validate-form"  [formGroup]="feedbackForm" (ngSubmit)="onSubmit()" autocomplete="off">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label  for="feedback_type">Feedback Type</label>
                          <span class="required" aria-required="true"> * </span>
                          <ng-select [items]="feedbackType" id="feedback_type" [loading]="selectBasicLoading"  bindValue="id" bindLabel="name"  placeholder="Select Feedback Type" formControlName="feedback_type"  [ngClass]="{ 'is-invalid': submitted && f.feedback_type.errors }" required> </ng-select>
                          <div *ngIf="submitted && f.feedback_type.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.feedback_type.errors }">
                            <div *ngIf="f.feedback_type.errors.required">Feedback Type is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label  for="feedback_text">Feedback</label>
                          <span class="required" aria-required="true"> * </span>
                          <fieldset class="form-group">
                            <textarea class="form-control" id="feedback_text" rows="3" formControlName="feedback_text" placeholder="Enter Message" [ngClass]="{ 'is-invalid': submitted && f.feedback_text.errors }"></textarea>
                          </fieldset>
                          <div *ngIf="submitted && f.feedback_text.errors" class="invalid-feedback" [ngClass]="{ 'd-block': submitted && f.feedback_text.errors }">
                            <div *ngIf="f.feedback_text.errors.required">Feedback is required</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pull-right">
                      <div class="col-md-12  d-flex">
                        <button [disabled]="loading" class="btn btn-primary mr-1 " type="submit" rippleEffect>
                          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Submit
                        </button>
                        <button [disabled]="loading" type="button" routerLink="/home" class="btn btn-secondary mr-1"  rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                        </button>
                      </div>
                    </div>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>
    