<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- account setting page -->
    <section id="page-account-settings">
      <div class="row ">
        <div class="col-md-12 col-12">
          <div class="card">
            <div class="overlay" *ngIf="isLoading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="card-body">
              <!-- form -->
                <form class="validate-form " enctype="multipart/form-data" [formGroup]="profileForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="row">
                    <div class="col-sm-6 col-md-12 col-lg-12 col-12 mb-1">
                      <div role="button" class="bg-light-success p-1 w-100 d-flex rounded justify-content-between align-items-center" (click)="copyText()">
                          <div>
                           <span class="font-weight-bolder text-primary"> Referral Code: {{referralCode}}</span>
                          </div>
                          <div class="text-primary">
                            <span [data-feather]="'copy'"></span>
                          </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <!-- header media -->
                      <div class="media">
                        <a href="javascript:void(0);" class="mr-25">
                          <img [src]="avatarImage" *ngIf="avatarImage != ''" class="rounded mr-50" alt="profile image" height="76" width="80" />
                        </a>
                        <!-- upload and reset button -->
                        <div class="media-body mt-75 ml-1">
                          <button (click)="file.click()" [disabled]="loadingImage" for="account-upload"  type="button" class="btn btn-sm btn-primary mb-75 mr-75" rippleEffect>
                            Upload
                          </button>
                          <input type="file" #file id="account-upload" hidden accept="image/*" (change)="uploadImage($event)"/>
                          <button class="btn btn-sm btn-outline-secondary mb-75" [disabled]="loadingImage" type="button" (click)="resetProfile()" rippleEffect>Reset</button>
                          <p class="badge badge-light-primary">User Code: {{userCode}}</p>
                        </div>
                        <!--/ upload and reset button -->
                      </div>
                    <!--/ header media -->
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="name">Name</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'user'"></span></span>
                          </div>
                          <input type="text" id="name" class="form-control" formControlName="name" placeholder=" Enter Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="mobile_number">Mobile</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend" readonly>
                            <span class="input-group-text"><span [data-feather]="'smartphone'"></span></span>
                          </div>
                          <input type="text" id="mobile_number" class="form-control" formControlName="mobile_number" readonly placeholder="Enter Mobile" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'mail'"></span></span>
                          </div>
                          <input type="email" id="email" class="form-control" formControlName="email" placeholder="Enter Email" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="address1">Address Line 1</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'home'"></span></span>
                          </div>
                          <input type="text" id="address1" class="form-control" formControlName="address1" placeholder="Enter Address One"  [ngClass]="{ 'is-invalid': submitted && f.address1.errors }" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="address2">Address Line 2</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'home'"></span></span>
                          </div>
                          <input type="text" id="address2" class="form-control" formControlName="address2" placeholder="Enter Address Two"  [ngClass]="{ 'is-invalid': submitted && f.address2.errors }" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="address3">Address Line 3</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'home'"></span></span>
                          </div>
                          <input type="text" id="address3" class="form-control" formControlName="address3" placeholder="Enter Address Three" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label for="landmark">Landmark</label>
                        <div class="input-group input-group-merge">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><span [data-feather]="'map-pin'"></span></span>
                          </div>
                          <input type="text" id="landmark" class="form-control" formControlName="landmark" placeholder="Enter Landmark" [ngClass]="{ 'is-invalid': submitted && f.landmark.errors }" />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label  for="state_id">Select State</label>
                        <span class="required" aria-required="true"> * </span>
                        <ng-select [items]="stateData" id="state_id" [clearable]="false" (change)="getCityList($event)" [loading]="selectBasicLoading" bindValue="id" bindLabel="state_name"  placeholder="Select State" formControlName="state_id" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"> </ng-select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label  for="city_id">Select City</label>
                        <span class="required" aria-required="true"> * </span>
                        <ng-select [items]="cityData" id="city_id"  [clearable]="false" (change)="getPincodeList($event)" [loading]="selectCityBasicLoading" bindValue="id" bindLabel="city_name"  placeholder="Select City" formControlName="city_id" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"> </ng-select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3 col-lg-3 col-12">
                      <div class="form-group">
                        <label  for="pincode_id">Select Pincode</label>
                        <span class="required" aria-required="true"> * </span>
                        <ng-select [items]="pincodeData" id="pincode_id" [clearable]="false" [loading]="selectPincodeBasicLoading" bindValue="id" bindLabel="pincode"  placeholder="Select Pincode" formControlName="pincode_id" [ngClass]="{ 'is-invalid': submitted && f.pincode_id.errors }"> </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="pull-right pt-1">
                    <div class="col-md-12  d-flex">
                      <button [disabled]="loading" class="btn btn-primary mr-1 " type="submit" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Update
                      </button>
                      <button [disabled]="loading" type="button" routerLink="/home" class="btn btn-secondary mr-1"  rippleEffect>
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                      </button>
                    </div>
                  </div>
                </form>
              <!--/ form -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
  