import { Component, ElementRef, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FeedbackService } from 'app/_services/feedback.service';
import { DashboardService } from 'app/_services/dashboard.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  public currentUser: any;
  public feedbackType: any;
  public selectBasicLoading = true;
  public feedbackForm: FormGroup;
  public loading = false;
  public submitted = false;
  public userId:any;

  constructor(private elementRef: ElementRef, private fb: FormBuilder, private dashboardService:DashboardService, private _authenticationService: AuthenticationService, private feedbackService:FeedbackService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.feedbackForm = this.fb.group({
      feedback_type: [null, Validators.required],
      feedback_text: ['',Validators.required],
    });
    this.getFeedbackTypes();
  }

  get f() {
    return this.feedbackForm.controls;
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }

  onSubmit() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      this.feedbackForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
    }else {
      this.loading = true;
      this.feedbackService.addFeedback( this.currentUser.result.user_id,this.f.feedback_type.value,this.f.feedback_text.value).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.feedbackForm.reset();
            this.toastr.success(data.message, 'Success!');
            this._router.navigate(['/home']);
          }
          else {
            this.toastr.success(data.error.message, 'Success!');
          }
        },err => {
          this.toastr.error(err.error.message, 'Opps!');
        });
    }
  }

  getFeedbackTypes(){
    this.dashboardService.getDashboardData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.selectBasicLoading=false;
          this.feedbackType = data.result.feedback_types;
        }
        else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }


}
