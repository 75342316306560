<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="page-account-settings">
        <div class="row" >
          <div class="col-md-12 col-12">
            <div class="card">
                <div class="overlay" *ngIf="isLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only text-dark">Loading...</span>
                    </div>
                    <div>
                        Please Wait...
                    </div>
                </div>
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" class="nav nav-pills justify-content-center">
                        <li ngbNavItem>
                        <a ngbNavLink>General</a>
                        <ng-template ngbNavContent>
                            <div class="row match-height"  *ngIf="general?.length != 0">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 " *ngFor="let ge of general;">
                                    <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="mr-1">
                                                <i data-feather="disc" [class]="'text-primary'" [size]="22"></i>
                                            </div> 
                                            <div>
                                                <p class="text-dark mb-0">{{ge.notification}}</p>
                                                <span class="float-right text-secondary"><b>{{ ge.added_at | date: 'dd/MM/yyyy hh:MM:ss' }}</b></span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"  *ngIf="general?.length == 0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <h3 class="text-primary">No Notification Found</h3>
                                </div>
                            </div>
                        </ng-template>
                        </li>
                        <li ngbNavItem>
                        <a ngbNavLink>Offer</a>
                        <ng-template ngbNavContent>
                            <div class="row match-height"  *ngIf="offer?.length != 0">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 " *ngFor="let off of offer;">
                                    <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="mr-1">
                                                <i data-feather="disc" [class]="'text-primary'" [size]="22"></i>
                                            </div> 
                                            <div>
                                                <p class="text-dark mb-0">{{off.notification}}</p>
                                                <span class="float-right text-secondary"><b>{{ off.added_at | date: 'dd/MM/yyyy hh:MM:ss' }}</b></span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"  *ngIf="offer?.length == 0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <h3 class="text-primary">No Notification Found</h3>
                                </div>
                            </div>
                        </ng-template>
                        </li>
                        <li ngbNavItem>
                        <a ngbNavLink>Recharge</a>
                        <ng-template ngbNavContent>
                            <div class="row match-height"  *ngIf="recharge?.length != 0">
                                <div class="col-4 col-sm-4 col-md-4 col-lg-4 " *ngFor="let rec of recharge;">
                                    <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                        <div class="card-body d-flex justify-content-center align-items-center">
                                            <div class="mr-1">
                                                <i data-feather="disc" [class]="'text-primary'" [size]="22"></i>
                                            </div> 
                                            <div>
                                                <p class="text-dark mb-0">{{rec.notification}}</p>
                                                <span class="float-right text-secondary"><b>{{ rec.added_at | date: 'dd/MM/yyyy hh:MM:ss' }}</b></span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"  *ngIf="recharge?.length == 0">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                    <h3 class="text-primary">No Notification Found</h3>
                                </div>
                            </div>
                        </ng-template>
                        </li>
                    </ul>
                  <div [ngbNavOutlet]="nav" class="mt-1"></div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>
    