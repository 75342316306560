<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <section id="page-account-settings">
        <div class="row" *ngIf="isLoading">
          <div class="col-md-12 col-12">
            <div class="card">
              <div class="card-body">
                <div class="overlay">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only text-dark">Loading...</span>
                  </div>
                  <div>
                    Please Wait...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row match-height"  *ngIf="rechargeHistory?.length != 0">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let rh of rechargeHistory;">
            <div class="card">
              <div class="card-body">
                <h6 class="text-primary font-weight-bolder">{{rh.product.product_name}}</h6>
                <p class="text-dark">{{rh.product.product_description}}</p>
                <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <b>{{ rh.recharge_date | date: 'dd/MM/yyyy' }}</b>
                    </div>
                    <div class="media">
                      <div class="media-body my-auto" style="margin-right:5px ;">
                        <b class="font-weight-bolder mb-0">Quantity:</b>
                      </div>
                      <div class="avatar bg-primary">
                        <div class="avatar-content">
                          <div class="font-weight-bolder text-white"> {{rh.quentity}}L</div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="rechargeHistory?.length == 0">
          <div class="col-md-12 col-12">
            <div class="card">
              <div class="card-body">
                <div class="font-weight-bolder text-dark">No Data!</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
</div>
    