<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <section id="page-account-settings">
      <div class="row ">
        <div class="col-md-12 col-12">
          <div class="card">
            <div class="overlay" *ngIf="isLoading">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="card-body">
              <!-- form -->
                <form class="validate-form" [formGroup]="deliveryForm" (ngSubmit)="onSubmit()" autocomplete="off">
                  <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-6 col-12">
                      <div class="form-group"> 
                        <label  for="product_id">Select Product</label>
                        <span class="required" aria-required="true"> * </span>
                        <ng-select [items]="productData" (change)="changeProduct($event)" id="product_id" [clearable]="false" [loading]="selectBasicLoading" bindValue="id" bindLabel="name" formControlName="product_id"  placeholder="Select Product" [ngClass]="{ 'is-invalid': submitted && f.product_id.errors }"> </ng-select>
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6 col-12">
                      <label >Delivery Type</label>  <span class="required" aria-required="true"> * </span>
                      <div class="d-flex flex-wrap" style="margin-top:0.5rem;" >
                          <div class="custom-control custom-radio mr-1 mb-1">
                              <input type="radio" id="customRadio1"  formControlName="selected_day" (click)="customDayRemove()" value="daily" class="custom-control-input"  [ngClass]="{ 'is-invalid': submitted && f.selected_day.errors }" />
                              <label class="custom-control-label" for="customRadio1">Daily</label>
                          </div>
                          <div class="custom-control custom-radio mr-1 mb-1">
                              <input type="radio" id="customRadio2"  formControlName="selected_day" (click)="customDayRemove()" value="alternate day" class="custom-control-input" [ngClass]="{ 'is-invalid': submitted && f.selected_day.errors }" />
                              <label class="custom-control-label" for="customRadio2">Alternate Day</label>
                          </div>
                          <div class="custom-control custom-radio mr-1 mb-1">
                              <input type="radio" id="customRadio3"  formControlName="selected_day" (click)="customDay()" value="custom"  class="custom-control-input" [ngClass]="{ 'is-invalid': submitted && f.selected_day.errors }"/>
                              <label class="custom-control-label" for="customRadio3">Custom </label>
                          </div>
                      </div>
                      <div *ngIf="customSelect == true">
                          <div class="d-flex flex-wrap mb-1" style="margin-top:0.5rem;">
                              <div  *ngFor="let item of customDayList" class="custom-control custom-control-info custom-checkbox mr-1 mb-1">
                                  <input type="checkbox" [(ngModel)]="item.checked" [ngModelOptions]="{standalone: true}" class="custom-control-input"  id="colorCheck{{item.slug}}"  />
                                  <label class="custom-control-label" for="colorCheck{{item.slug}}">{{item.title}}</label>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="pull-right">
                    <div class="col-md-12  d-flex">
                      <button [disabled]="loading" class="btn btn-primary mr-1 " type="submit" rippleEffect>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                      </button>
                      <button [disabled]="loading" type="button" routerLink="/home" class="btn btn-secondary mr-1"  rippleEffect>
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Cancel
                      </button>
                    </div>
                  </div>
                </form>
              <!--/ form -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
    