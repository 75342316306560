import { Component, ElementRef, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { ProfileService } from 'app/_services/profile.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public currentUser: any;
  public profile: any;
  public isLoading: Boolean =true;
  public profileForm: FormGroup;

  public avatarImage: string;
  public referralCode: any;
  public userCode: any;
  public stateData: any;
  public cityData: any;
  public pincodeData: any;
  public selectBasicLoading = true;
  public selectCityBasicLoading = true;
  public selectPincodeBasicLoading = true;

  public birth_date:any;
  public loading = false;
  public submitted = false;
  public userId:any;

  public loadingImage = false;
  public submittedImage = false;

  

  public DefaultDateStart: FlatpickrOptions = {
    altFormat: 'd-m-Y',
    //defaultDate: new Date( new Date().getFullYear(),  new Date().getMonth(), 1),
    allowInput: true,
    altInput: true,
    maxDate:new Date(),
  }

  constructor(private elementRef: ElementRef, private clipboardApi: ClipboardService, private fb: FormBuilder, private _authenticationService: AuthenticationService, private profileService:ProfileService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit(): void {
    this.avatarImage = '/assets/images/avatar-default.png';
    this.currentUser = this._authenticationService.currentUserValue;
    this.getProfileData(this.currentUser.result.user_id);
    this.profileForm = this.fb.group({
      name: ['', Validators.required],
      mobile_number: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['',Validators.email],
      address1: ['',Validators.required],
      address2: [''],
      address3: [''],
      landmark: [null,Validators.required],
      state_id: [null,Validators.required],
      city_id: [null,Validators.required],
      pincode_id: [null,Validators.required]
    });
    this.getStateList();
  }

  get f() {
    return this.profileForm.controls;
  }

  getProfileData(id:any){
    this.profileService.getProfile(id).subscribe(
      data => {
        this.isLoading = false;
        if (data.status == true) {
          this.getCityList(data.result.state_id);
          this.getPincodeList(data.result.city_id);
          const [day, month, year,] = data.result.birthdate.split('-');
          this.birth_date =  year + '-' + month + '-' + day;
          this.profileForm.setValue({
            name: ((data.result.name && data.result.name != 'null') ? data.result.name : ''),
            mobile_number: ((data.result.mobile_number && data.result.mobile_number != 'null') ? data.result.mobile_number : ''),
            email:  ((data.result.email && data.result.email != 'null') ? data.result.email : ''),
            address1: ((data.result.address1 && data.result.address1 != 'null') ? data.result.address1 : ''),
            address2: ((data.result.address2 && data.result.address2 != 'null') ? data.result.address2 : ''),
            address3: ((data.result.address3 && data.result.address3 != 'null') ? data.result.address3 : ''),
            landmark: ((data.result.landmark && data.result.landmark != 'null') ? data.result.landmark : ''),
            city_id: data.result.city_id,
            state_id: data.result.state_id,
            pincode_id: data.result.pincode_id,
          });
          this.userId = data.result.user_id;
          this.avatarImage = data.result.profile_photo;
          this.userCode = data.result.user_code;
          this.referralCode = data.result.referral_code;
        }else{
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.isLoading = true;
        if (!err.error.status) {
          this.toastr.error(err.error.message, 'Error!');
        }else{
          this.toastr.error('Something went wrong', 'Error!');
        }
      }
    );
  }

  getStateList(){
    this.profileService.getState().subscribe(
      data => {
        if (data.status) {
          this.selectBasicLoading=false;
          this.stateData = data.result;
        }
        else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  getCityList(stateId:any){
    this.profileService.getCity(stateId).subscribe(
      data => {
        if (data.status) {
          this.selectCityBasicLoading=false;
          this.cityData = data.result;
        }
        else {
          this.selectCityBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectCityBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  getPincodeList(cityId:any){
    this.profileService.getPincode(cityId).subscribe(
      data => {
        if (data.status) {
          this.selectPincodeBasicLoading=false;
          this.pincodeData = data.result;
        }
        else {
          this.selectPincodeBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectPincodeBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  uploadImage(event: any) {
    let profile = '';
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        this.avatarImage = event.target.result;
      };
      profile = event.target.files[0];
      console.log(profile);
      this.updateProfile(profile);
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  resetProfile(){
    let profile = '';
    this.updateProfile(profile);
    this.getProfileData(this.currentUser.result.user_id);
  }

  copyText() {
    this.clipboardApi.copyFromContent(this.referralCode);
    this.toastr.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.elementRef.nativeElement.querySelector(
      ".is-invalid"
    );
    firstInvalidControl.focus(); //without smooth behavior
  }

  /** On Submit **/
  onSubmit() {
    this.submitted = true;
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
      this.loading = false;
    }else {
      this.loading = true;
      let formdata = new FormData();
      formdata.append('user_name', this.f.name.value);
      formdata.append('email', this.f.email.value);
      formdata.append('address1', this.f.address1.value);
      formdata.append('address2', this.f.address2.value);
      formdata.append('address3', this.f.address3.value);
      formdata.append('landmark', this.f.landmark.value);
      formdata.append('city', this.f.city_id.value);
      formdata.append('state', this.f.state_id.value);
      formdata.append('pincode', this.f.pincode_id.value);
     // formdata.append('birthdate', this.convert(this.f.birthdate.value));
      formdata.append('user_id', this.userId);
      this.profileService.updateProfile(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.userId = '';
            this.submitted = false;
            this.toastr.success(data.message, 'Success!');
          }
          else {
            this.toastr.success(data.error.message, 'Success!');
          }
        },err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }

  convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), month, day].join("-");
  }

  /** Update Image **/
  updateProfile(profile) {
    this.loadingImage = true;
    let formdata = new FormData();
    formdata.append('profile_photo', profile);
    formdata.append('user_id', this.userId);

    this.profileService.updateProfileImage(formdata).subscribe(
      data => {
        this.loadingImage = false;
        if (data.status) {
          this.userId = 0
          this.toastr.success(data.message, 'Success!');
        }
        else {
          this.toastr.success(data.error.message, 'Success!');
        }
      },err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

}
