import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'app/_services/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public currentUser: any;
  public general: any;
  public offer: any;
  public recharge: any;
  public isLoading: Boolean =true;

  constructor(private _authenticationService: AuthenticationService, private notificationService:NotificationService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.getNotificationList();
  }

  getNotificationList(){
    this.notificationService.getNotificationData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading=false;
          this.general = data.result.general;
          this.offer = data.result.offer;
          this.recharge = data.result.recharge;
        }
        else {
          this.isLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },err => {
        this.isLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }
 
}
