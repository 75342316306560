import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { JwtInterceptorProviders } from './auth/helpers/jwt.interceptor';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgOtpInputModule } from  'ng-otp-input';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

import { coreConfig } from 'app/app-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AuthLoginV2Component } from './main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { AuthGuard } from './auth/helpers';
import { Role } from './auth/models';
import { ProfileComponent } from './component/profile/profile.component';
import { DeliveryOptionsComponent } from './component/delivery-options/delivery-options.component';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { ClipboardModule } from 'ngx-clipboard';
import { RechargeHistoryComponent } from './component/recharge-history/recharge-history.component';
import { NotificationComponent } from './component/notification/notification.component';
import { FeedbackComponent } from './component/feedback/feedback.component';
import { ReferAndEarnComponent } from './component/refer-and-earn/refer-and-earn.component';
import { HomeComponent } from './component/home/home.component';
import { PastOrderComponent } from './component/past-order/past-order.component';
import { ManageVacationComponent } from './component/manage-vacation/manage-vacation.component';
import { TomorrowOrderComponent } from './component/tomorrow-order/tomorrow-order.component';
import { ProductsComponent } from './component/products/products.component';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { RegisterComponent } from './component/register/register.component';

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]);
const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', roles: [Role.Client,Role.Admin] }

  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }

  },
  {
    path: 'manage-vacation',
    component: ManageVacationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }

  },
  {
    path: 'tomorrow-order',
    component: TomorrowOrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }

  },
  {
    path: 'delivery-option',
    component: DeliveryOptionsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'recharge-history',
    component: RechargeHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'past-order',
    component: PastOrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'notification',
    component: NotificationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
  {
    path: 'refer-and-earn',
    component: ReferAndEarnComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',roles: [Role.Client] }
  },
 
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent,
    pathMatch: 'full'
  },
 
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  declarations: [AppComponent, ProfileComponent, DeliveryOptionsComponent, RechargeHistoryComponent, NotificationComponent, FeedbackComponent, ReferAndEarnComponent, HomeComponent, PastOrderComponent, ManageVacationComponent, TomorrowOrderComponent, ProductsComponent, ForgotPasswordComponent, RegisterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),
    FullCalendarModule,
    NgOtpInputModule,

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CoreDirectivesModule,
    CoreTouchspinModule,
    ContentHeaderModule,
    

    // App modules
    LayoutModule,
    SampleModule,
    Ng2FlatpickrModule,
    ClipboardModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
   

    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
  ],
  providers: [JwtInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
