import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};


@Injectable({
  providedIn: 'root'
})
export class ManageVacationService {

  constructor(private http: HttpClient) { }

  getVacationData(user_id:string, product_id:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}user-vacation-list`, {user_id, product_id});
  }

  addVacation(vacationData: any,vacationId:string): Observable<any> {
    if(vacationId == undefined){
      return this.http.post(`${environment.apiUrl}user-vacation-save`, vacationData);
    }else{
      return this.http.post(`${environment.apiUrl}user-vacation-edit`, vacationData);
    }
  }

  deleteVacation(user_id:string, vacation_id:string): Observable<any>{
    return this.http.post(`${environment.apiUrl}user-vacation-delete`, {user_id, vacation_id});
  }

}
 