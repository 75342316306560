<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row ">
                <div class="col-md-12 col-12">
                    <div class="card"  *ngIf="isLoading">
                        <div class="card-body">
                            <div class="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                                <div>
                                    Please Wait...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row match-height"  *ngIf="productData?.length != 0">
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let pr of productData;">
                            <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                <div class="card-body p-1">
                                    <div class="row ">
                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center align-items-center">
                                            <ngb-carousel *ngIf="pr.photo" class="carousel-fade">
                                                <ng-template ngbSlide *ngFor="let pho of pr.photo;">
                                                <div class="carousel-img-wrapper">
                                                    <img [src]="pho.product_photo" class="img-fluid" alt="Random first slide" />
                                                </div>
                                                <div class="carousel-caption"></div>
                                                </ng-template>
                                            </ngb-carousel>
                                            <img src="assets/images/logo/go-organic-logo.png" *ngIf="pr.photo.length == 0" class="rounded mr-50" alt="Referrals" height="76" width="80" />
                                        </div>
                                        <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                                            <h6 class="text-primary font-weight-bolder">{{pr.product_name}}</h6>
                                            <p class="text-dark">
                                                {{pr.product_description}}
                                            </p>

                                            <button type="button" id="auto_trigger_{{ pr.id }}" (click)="viewDetails(pr,modalSM)" class="btn btn-outline-primary round" rippleEffect>View Details</button>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row"  *ngIf="productData?.length == 0">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                            <h3 class="text-primary">No Product Found</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
            <ng-template #modalSM let-modal>
                <div class="overlay" *ngIf="isModelLoading">
                    <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <b>Add Order</b></span>
                    <span class="float-right"> <span  class="badge badge-info">{{productDetails.product_name}}</span ></span>
                </h5>
            
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                        <div class="card-body ">
                                            <ngb-carousel *ngIf="productDetails.photo" class="carousel-fade">
                                                <ng-template ngbSlide *ngFor="let pho of productDetails.photo;">
                                                <div class="carousel-img-wrapper">
                                                    <img [src]="pho.product_photo" class="img-fluid" alt="Random first slide" />
                                                </div>
                                                <div class="carousel-caption"></div>
                                                </ng-template>
                                            </ngb-carousel>
                                            <img src="assets/images/logo/go-organic-logo.png" *ngIf="productDetails.photo.length == 0" class="rounded mr-50" alt="Referrals" height="76" width="80" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                                    <h4 class="text-primary font-weight-bolder mt-2">{{productDetails.product_name}}</h4>
                                    <h3>
                                        <span class="text-dark">Rs.{{productDetails.product_price - productDetails.product_discount}}</span> 
                                        <span *ngIf="productDetails.product_discount > 0 || productDetails.product_discount > '0'">/ <span class="text-danger" style="text-decoration:line-through"> Rs.{{productDetails.product_price}}</span></span>
                                        &nbsp;<small>Tax included.</small>
                                    </h3>
                                    <p class="text-dark">
                                        {{productDetails.product_description}}
                                    </p>
                                    <p>
                                        <b>Qty.</b> <core-touchspin [minValue]="min_qty_to_recharge" [size]="'lg'" (onChange)="countChange($event)" [numberValue]="min_qty_to_recharge" [stepValue]="1"></core-touchspin>  
                                    </p>
                                    <p>
                                        <b>Date: {{orderDate | date:"dd-MM-yyyy"}}</b>
                                    </p>
                                    <p>
                                        <button type="button" [disabled]="loading" *ngIf="productDetails.is_default == 1" (click)="addOrder(productDetails)" class="btn btn-primary">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Recharge
                                        </button>  
                                        <button type="button" [disabled]="loading" *ngIf="productDetails.is_default == 0" (click)="addOrder(productDetails)" class="btn btn-primary">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span> Buy Now
                                        </button>  
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        <!-- / Modal -->
    </div>
</div>
      