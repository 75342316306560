import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/angular';
import { PastOrderService } from 'app/_services/past-order.service';
import { AuthenticationService } from 'app/auth/service';
import { DeliveryOptionsService } from 'app/_services/delivery-options.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Component({
  selector: 'app-past-order',
  templateUrl: './past-order.component.html',
  styleUrls: ['./past-order.component.scss']
})
export class PastOrderComponent implements OnInit {

public productData:any = [];
public orderHistory:any = [];
public vacationHistory:any = [];
public productId:any;
public localProductId:any;
public isLoading: Boolean =true;
public selectBasicLoading = true;
public calendarForm: FormGroup;
public currentUser: any;

public events = [];
public event;

public calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'title',
      right: 'prev,next'
    },
    initialView: 'dayGridMonth',
    initialEvents: this.events,
    weekends: true,
    editable: false,
    eventResizableFromStart: true,
    selectable: false,
    selectMirror: false,
    dayMaxEvents: 1,
    eventDisplay: 'block',
    navLinks: false,
    displayEventTime : false,
    eventClassNames: this.eventClass.bind(this),
};

  constructor(private toastr: ToastrService, private fb: FormBuilder, private pastOrderService:PastOrderService,  private _authenticationService: AuthenticationService, private deliverOption:DeliveryOptionsService) {}

  /**
   * Add Event Class
   *
   * @param s
   */
   eventClass(s) {
    const calendarsColor = {
      Business: 'primary',
      Holiday: 'success',
      Personal: 'danger',
      Family: 'warning',
      ETC: 'info'
    };

    const colorName = calendarsColor[s.event._def.extendedProps.calendar];
    return `bg-light-${colorName}`;
  }

  
  /**
   * On init
   */
  ngOnInit(): void {
    this.calendarForm = this.fb.group({
      product_id: [null, [Validators.required]]
    });
    this.currentUser = this._authenticationService.currentUserValue;
    this.getProductList();
    this.localProductId = localStorage.getItem('order_p_id');
  }

  getProductList(){
    this.deliverOption.getProductData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.selectBasicLoading=false;
          let arrMy = [];
          for (var i = 0; i < data.result.length; i++) {
            if (data.result[i].is_default == 1) {
              arrMy.push({id: data.result[i].id, name: data.result[i].product_name, selected_day:data.result[i].selected_day});
            }
          }
          this.productData = arrMy;
          if(this.productData.length > 0){
            if(this.localProductId != null){
              this.productId = Number(this.localProductId);
            }else{
              this.productId = this.productData[0].id;
            }
            this.calendarForm.setValue({
              product_id:  this.productId,
            });
            this.getCalendarOrderHistoryList(this.productId);
            this.getCalendarVacationHistoryList(this.productId);
          }
          
        }else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  getCalendarOrderHistoryList(productId:any){
    this.pastOrderService.getCalendarOrderHistory(this.currentUser.result.user_id,productId).subscribe(
      data => {
        if (data.status) {
          let arrOrder = [];
          for (var i = 0; i < data.result.length; i++) {
            arrOrder.push({title: data.result[i].product_qty+'L', start: data.result[i].order_date, end: data.result[i].order_date, calendar: 'Holiday'});
          }
          this.orderHistory = arrOrder; 
          this.addCalendar();
        }else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
    
  }

  getCalendarVacationHistoryList(productId:any){
    this.pastOrderService.getCalendarVacationHistory(this.currentUser.result.user_id,productId).subscribe(
      data => {
        if (data.status) {
          let arrVacation = [];
          for (var i = 0; i < data.result.length; i++) {
            arrVacation.push({title: 'Vacation', start: data.result[i].start_date+'T00:00:00.0+0100', end: data.result[i].end_date+'T00:00:00.0+0100', calendar: 'Personal'});
          }
          this.vacationHistory = arrVacation;
          console.log( this.vacationHistory);
          this.addCalendar();
        }else {
          this.selectBasicLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
    
  }

  addCalendar(){
    this.isLoading = false;
    let arrayMarge =  this.vacationHistory.concat(this.orderHistory); 
    this.events = arrayMarge;
    this.calendarOptions.events = arrayMarge;
    localStorage.removeItem("order_p_id");
  }

  changeProduct(event:any){
    this.productId = event.id;
    this.getCalendarOrderHistoryList(this.productId);
    this.getCalendarVacationHistoryList(this.productId);
    this.isLoading = true;
  }

}
