import { Component, OnInit, ChangeDetectorRef,ViewChild} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DashboardService } from 'app/_services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  @ViewChild("modalSM",{static:true}) modalSM:any;

  public currentUser: any;
  public productData: any;
  public isLoading: Boolean =true;
  public isModelLoading: Boolean =true;
  public productLowDetails: any;

  constructor(private _authenticationService: AuthenticationService, private dashboardService:DashboardService, private toastr: ToastrService, private _router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.currentUser = this._authenticationService.currentUserValue;
    this.getDetails();
  }

  getDetails(){
    this.dashboardService.getDashboardData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading=false;
          this.productData = data.result.product;
          //let productArray = []
          for (var i = 0; i < this.productData.length; i++) {
            if((this.productData[i].id == 1) && (this.productData[i].account_balance == '0.0') && (this.productData[i].total_liters_ordered == '0.0')){
              this.productLowDetails = this.productData[i];
            }
          }
         // this.productLowDetails = productArray
          if(this.productLowDetails != undefined){
            this.isModelLoading = false;
            this.modalService.open(this.modalSM, {
              centered: true,
              size: 'sm'
            });
          }
        }
        else {
          this.isLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },err => {
        this.isLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }

  moveVacation(id:any){
    this._router.navigate(['/manage-vacation'], {skipLocationChange: false})
    localStorage.setItem('v_product_id',id);
  } 

  moveTomorrow(id:any, quantity:any){
    this._router.navigate(['/tomorrow-order'], {skipLocationChange: false})
    localStorage.setItem('product_id',id);
    localStorage.setItem('tomorrow_order_qty',quantity);
  } 

  movePastOrderHistory(id:any){
    this._router.navigate(['/past-order'], {skipLocationChange: false})
    localStorage.setItem('order_p_id',id);
  } 

  moveRechargeHistory(id:any){
    this._router.navigate(['/products'], {skipLocationChange: false})
    localStorage.setItem('recharge_p_id',id);
    this.modalService.dismissAll();
  } 

}
