<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="page-account-settings">
            <div class="row" >
              <div class="col-md-12 col-12">
                <div class="card">
                    <div class="overlay" *ngIf="isLoading">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only text-dark">Loading...</span>
                        </div>
                        <div>
                            Please Wait...
                        </div>
                    </div>
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" class="nav nav-pills justify-content-center">
                            <li ngbNavItem>
                                <a ngbNavLink>Share</a>
                                <ng-template ngbNavContent>
                                    <div class="row match-height justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-8 col-lg-8 ">
                                            <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                                <div class="card-body text-center pb-1">
                                                   <div class="d-flex justify-content-center pb-2">
                                                    <img src="assets/images/share-user.png" class="rounded " alt="share user" height="100" width="100" />
                                                   </div> 
                                                  <h4>{{referTitle}}</h4>  
                                                  <p>{{referDescription}}</p>
                                                  <button type="button" class="w-50 mb-2 btn btn-outline-primary" rippleEffect (click)="copyText()">
                                                    <div class=" d-flex rounded  justify-content-between align-items-center" >
                                                        <div>
                                                            <span class="font-weight-bolder text-primary"> <i data-feather="users" class="font-weight-bolder mr-1" [size]="20"></i> {{this.currentUser.result.referral_code}}</span>
                                                        </div>
                                                        <div class="text-primary">
                                                            <span [data-feather]="'copy'"></span>
                                                        </div>
                                                    </div>
                                                  </button> <br>
                                                   <!-- <button type="button" class="btn btn-relief-primary">Refer And Earn</button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                            <li ngbNavItem>
                                <a ngbNavLink>Referrals</a>
                                <ng-template ngbNavContent>
                                    <div class="row match-height"  *ngIf="referHistory?.length != 0">
                                        <div class="col-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let rh of referHistory;">
                                            <div class="card" style="box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important">
                                                <div class="card-body p-1">
                                                    <div class="row no-gutters">
                                                        <div class="col-4 col-sm-4 col-md-4 col-lg-4 d-flex justify-content-center align-items-center">
                                                            <img [src]="rh.profile_photo" *ngIf="rh.profile_photo != 'avatar-default.png'" class="rounded mr-50" alt="Referrals" height="76" width="80" />
                                                            <img src="assets/images/logo/go-organic-logo.png" *ngIf="rh.profile_photo == 'avatar-default.png'" class="rounded mr-50" alt="Referrals" height="76" width="80" />
                                                        </div>
                                                        <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                                                            <h6 class="text-primary font-weight-bolder">{{rh.name}}</h6>
                                                            <p class="text-dark">
                                                                <b>Mobile:</b> {{rh.mobile_number}}
                                                                <b>Email:</b> {{rh.email}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row"  *ngIf="referHistory?.length == 0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                                            <h3 class="text-primary">No Refer History Found</h3>
                                        </div>
                                    </div>
                                </ng-template>
                            </li>
                        </ul>
                      <div [ngbNavOutlet]="nav" class="mt-1"></div>
                    </div>
                </div>
              </div>
            </div>
        </section>
    </div>
</div>
    