import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  updateProfile(productData: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}user-profile-edit`, productData);
  }

  updateProfileImage(profileData: any): Observable<any> {
      return this.http.post(`${environment.apiUrl}upload-profile-pic`, profileData);
  }

  getProfile(user_id: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}user-profile`, {user_id});
  }

  getState(): Observable<any> {
    return this.http.get(`${environment.apiUrl}states-list`, httpOptions);
  }

  getCity(state_id:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}cities-list`, {state_id});
  }

  getPincode(city_id:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}picodes-list`, {city_id});
  }

}
 