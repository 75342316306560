import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  public currentUserRole = '';
  /**
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   */
  constructor(private _router: Router, private _authenticationService: AuthenticationService) { }

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (localStorage.getItem('currentUser') !== undefined) {
      this.currentUserRole = JSON.parse(localStorage.getItem('currentUser')).result.role;
    }
   
    if (this.currentUserRole) {
      // check if route is restricted by role
      if (route.data.roles && route.data.roles.indexOf(this.currentUserRole) === -1) {
        // role not authorised so redirect to not-authorized page
          this._router.navigate(['/home']);
        return true;
      }
      // authorised so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this._router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
