import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { AuthenticationService } from 'app/auth/service/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'app/_services/profile.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { RegisterService } from 'app/_services/register.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public coreConfig: any;
  public registerForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  
  public isRegisterForm:boolean = true;
  public isOtpForm:boolean = false;
  public passwordTextType: boolean;
  public passwordTextType1: boolean;
  public stateData: any;
  public cityData: any;
  public pincodeData: any;
  public selectBasicLoading = true;
  public selectCityBasicLoading = false;
  public selectPincodeBasicLoading = false;

  
  public otpForm: FormGroup;
  public otpSubmitted = false;
  public userId:any;
  public otp:any;
  public apiToken:any;
  public loadingOtp:boolean = false;

  public DefaultDateStart: FlatpickrOptions = {
    altFormat: 'd-m-Y',
    allowInput: true,
    altInput: true,
    maxDate:new Date(),
  }
  
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _toastrService: ToastrService,
    private _coreConfigService: CoreConfigService,
    private fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private profileService:ProfileService,
    private registerService:RegisterService,
    private toastr: ToastrService
  ) {
   this._unsubscribeAll = new Subject();
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }
 
  ngOnInit(): void {

    this.registerForm = this.fb.group({
      name: ['', Validators.required],
      mobile_number: ['', [Validators.required,Validators.pattern('^[0-9]*$'), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['',[Validators.email,Validators.required]],
      address1: ['',Validators.required],
      address2: [''],
      address3: [''],
      landmark: [null,Validators.required],
      state_id: [null,Validators.required],
      city_id: [null,Validators.required],
      pincode_id: [null,Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
      referral:[''],
    });

    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
    });

    this.getStateList();
    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }

  /**
   * Toggle password
   */
   togglePasswordTextType(me: any) {
    if (me == 0) {
      this.passwordTextType = !this.passwordTextType;
    }
    else {
      this.passwordTextType1 = !this.passwordTextType1;
    }
  }


  onSubmit() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this._toastrService.error('Something went wrong', 'Error!');
    }
    else {
      this.loading = true;
      let formData = new FormData();
      formData.append('user_name', this.f.name.value);
      formData.append('email', this.f.email.value);
      formData.append('mobile_number', this.f.mobile_number.value);
      formData.append('address1', this.f.address1.value);
      formData.append('address2', this.f.address2.value);
      formData.append('address3', this.f.address3.value);
      formData.append('landmark', this.f.landmark.value);
      formData.append('city', this.f.city_id.value);
      formData.append('state', this.f.state_id.value);
      formData.append('pincode', this.f.pincode_id.value);
      formData.append('password', this.f.password.value);
      formData.append('referral', this.f.referral.value);
      formData.append('device_token', 'web');
      formData.append('device_name', 'web');
      formData.append('app_version', '1.00');
      // if(this.f.birthdate.value != '' && this.f.birthdate.value != undefined){
      //   formData.append('birthdate', this.convert(this.f.birthdate.value));
      // }else{
        formData.append('birthdate', '');
      //}
      this.registerService.addRegister(formData).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.submitted = false;
            this.isRegisterForm = false;
            this.isOtpForm = true;
            this.userId = data.result.user_id;
            this.apiToken = data.result.api_token;
          }
          else {
            this._toastrService.error(data.message, 'Error!');
          }
        },err => {
          this._toastrService.error(err.error.message, 'Error!');
        });
    }
  }

  convert(str) {
    var date = new Date(str),
      month = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), month, day].join("-");
  }

  getStateList(){
    this.profileService.getState().subscribe(
      data => {
        if (data.status) {
          this.selectBasicLoading=false;
          this.stateData = data.result;
          this.cityData =[];
        }else {
          this.selectBasicLoading=true;
          this._toastrService.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectBasicLoading=true;
        this._toastrService.error(err.error.message, 'Opps!');
      }
    );
  }

  getCityList(stateId:any){
    this.selectCityBasicLoading=true;
    this.profileService.getCity(stateId.id).subscribe(
      data => {
        if (data.status) {
          this.selectCityBasicLoading=false;
          this.cityData = data.result;
        }else {
          this.selectCityBasicLoading=true;
          this._toastrService.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectCityBasicLoading=true;
        this._toastrService.error(err.error.message, 'Opps!');
      }
    );
  }

  getPincodeList(cityId:any){
    this.selectPincodeBasicLoading=true;
    this.profileService.getPincode(cityId.id).subscribe(
      data => {
        if (data.status) {
          this.selectPincodeBasicLoading=false;
          this.pincodeData = data.result;
        }else {
          this.selectPincodeBasicLoading=true;
          this._toastrService.error(data.message, 'Error!');
        }
      },
      err => {
        this.selectPincodeBasicLoading=true;
        this._toastrService.error(err.error.message, 'Opps!');
      }
    );
  }

  /*Otp Form Start*/

  get o() {
    return this.otpForm.controls;
  }

  onOtpChange(otp) {
    this.otp = otp;
    if(this.otp.length == 4){
      this.o.otp.setValue(this.otp);
    }else{
      this.o.otp.setValue(undefined);
    }
  }
  

  onSubmitOtp() {
    this.isRegisterForm = false;
    this.otpSubmitted = true;
    if (this.otpForm.invalid) {
      if(this.otp == undefined){
        this.toastr.error("Fill OTP", 'Opps!');
      }
      return;
    }else{
      this.loadingOtp = true;
      this.registerService.verifyOtp(this.userId, this.apiToken,Number(this.o.otp.value)).subscribe(
        data => {
          this.loadingOtp = false;
          if (data.status) {
            this.submitted = false;
            this.isRegisterForm = false;
            this.isOtpForm = false;
            this.userId = '';
            this.apiToken = '';
            this.otpForm.reset();
            this._router.navigate(['/login']);
            this.toastr.success(data.message, 'Success!');
          }
          else {
            this.toastr.error(data.message, 'Success!');
          }
        },err => {
          this.toastr.error(err.error.message, 'Opps!');
      });
    }
  }
  /*Otp Form End*/

  onReSendOtp(){
    this.registerService.reSendOtp(this.userId).subscribe(
      data => {
        if (data.status) {
          this.toastr.success(data.message, 'Success!');
        }
        else {
          this.toastr.error(data.message, 'Success!');
        }
      },err => {
        this.toastr.error(err.error.message, 'Opps!');
    });
  }
  
}
 