import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser} from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

function _window(): any {
  // return the global native browser window object
  return window;
}


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: object) { }

  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }

  getOrderId(amount: any, user_id:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}razorpay-order-id-create`, {amount, user_id});
  }

  addRecharge(orderData:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}recharge`, orderData);
  }
}
