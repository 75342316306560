import { Injectable} from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class PastOrderService {

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private http: HttpClient) {}

  getCalendarOrderHistory(user_id:string, product_id:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}order-history`, {user_id, product_id});
  }

  getCalendarVacationHistory(user_id:string, product_id:string): Observable<any> {
    return this.http.post(`${environment.apiUrl}user-vacation-list`, {user_id, product_id});
  }


}
