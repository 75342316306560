import { Component, OnInit} from '@angular/core';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ReferAndEarnService } from 'app/_services/refer-and-earn.service';
import { ClipboardService } from 'ngx-clipboard';


@Component({
  selector: 'app-refer-and-earn',
  templateUrl: './refer-and-earn.component.html',
  styleUrls: ['./refer-and-earn.component.scss']
})
export class ReferAndEarnComponent implements OnInit {

  public currentUser: any;
  public referHistory: any;
  public referTitle: any;
  public referDescription: any;
  public isLoading: Boolean =true;

  constructor(private _authenticationService: AuthenticationService, private clipboardApi: ClipboardService, private referAndEarnService:ReferAndEarnService, private toastr: ToastrService, private _router: Router) { }

  ngOnInit(): void {
    this.currentUser = this._authenticationService.currentUserValue;
    this.getRreferHistoryList();
  } 
  getRreferHistoryList(){
    this.referAndEarnService.getReferralsData(this.currentUser.result.user_id).subscribe(
      data => {
        if (data.status) {
          this.isLoading=false;
          this.referHistory = data.referrals;
          this.referTitle = data.title;
          this.referDescription = data.description;
        }
        else {
          this.isLoading=true;
          this.toastr.error(data.message, 'Error!');
        }
      },err => {
        this.isLoading=true;
        this.toastr.error(err.error.message, 'Opps!');
      }
    );
  }
  
  copyText() {
    this.clipboardApi.copyFromContent(this.currentUser.result.referral_code);
    this.toastr.success('', 'Copied!', { toastClass: 'toast ngx-toastr', closeButton: true });
  }

}
 