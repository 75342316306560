export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      SAMPLE: 'Sample',
      SETTING: 'Setting',
      PROFILE: 'Profile',
      DELIVERY_OPTIONS: 'Delivery Option',
      MANAGEVACATION: 'Manage Vacation',
      PASTORDER: 'Past Order',
      RECHARGEHISTORY: 'Recharge History',
      NOTIFICATION: 'Notification',
      FEEDBACK: 'Feedback',
      REFERANDEARN: 'Refer and Earn',
      PRODUCT: 'Products',
    }
  }
}
