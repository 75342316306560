import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})

export class RechargeHistoryService {

  constructor(private http: HttpClient) { }

  getRechargeHistory(user_id: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}recharge-history`, {user_id});
  }
}
