import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'setting',
    title: 'Setting',
    translate: 'MENU.SETTING',
    type: 'collapsible',
   // role: ['Client'],
    icon: 'settings',
    children: [
      {
        id: 'profile',
        title: 'Profile',
        translate: 'MENU.PROFILE',
        type: 'item',
        //role: ['Client'],
        icon: 'tag',
        url: 'profile'
      },
      {
        id: 'delivery-option',
        title: 'Delivery Option',
        translate: 'MENU.DELIVERY_OPTIONS',
        type: 'item',
       // role: ['Client'],
        icon: 'box',
        url: 'delivery-option'
      }
    ]
  },
  {
    id: 'manage_vacation',
    title: 'Manage Vacation',
    translate: 'MENU.MANAGEVACATION',
    type: 'item',
    icon: 'map',
    url: 'manage-vacation'
  },
  {
    id: 'past_order',
    title: 'Past Order',
    translate: 'MENU.PASTORDER',
    type: 'item',
    icon: 'shopping-cart',
    url: 'past-order'
  },
  {
    id: 'products',
    title: 'Products',
    translate: 'MENU.PRODUCT',
    type: 'item',
    icon: 'shopping-bag',
    url: 'products'
  },
  {
    id: 'recharge_history',
    title: 'Recharge History',
    translate: 'MENU.RECHARGEHISTORY',
    type: 'item',
    icon: 'battery-charging',
    url: 'recharge-history'
  },
  {
    id: 'feedback',
    title: 'Feedback',
    translate: 'MENU.FEEDBACK',
    type: 'item',
    icon: 'check-square',
    url: 'feedback'
  },
  {
    id: 'refer_and_earn',
    title: 'Refer and Earn',
    translate: 'MENU.REFERANDEARN',
    type: 'item',
    icon: 'share-2',
    url: 'refer-and-earn'
  }
]
